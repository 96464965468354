import React from "react"
// import { AuthService, useAuth } from "gatsby-theme-auth0"
import { navigate } from "gatsby" //import navigate from gatsby
const store = require("store")

let Login = () => {

  // const possProf = AuthService.getUserProfile()
  // if (possProf) {
  //   store.set("user", possProf)
  // }
  //
  // const { isLoggedIn } = useAuth()
  // // const profile = store.get('user');
  //
  // if (!isLoggedIn) {
  //   AuthService.login()
  // } else {
  //   navigate('/profile'); //navigate to edit page
  // }

  return (
    <div/>
  )
}

export default Login
